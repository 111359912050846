import { Advert, FormField } from '../components';
import { Button } from '../components';
import { useEffect, useRef, useState } from 'react';
import { setErrorIfInvalidResetPassword } from '../utils/validation';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux/store';
import { setToast } from '../redux/slices/appSlice';
import { ResetPasswordSubmit } from '../services/auth';
import PasswordValidator from './PasswordValidator';
import { OTPInput, ReturnToLogin } from './components';

function ResetPasswordStageTwo() {
	const passwordInputRef = useRef<HTMLInputElement>(null);
	const [otp, setOTP] = useState<string[]>(Array(6).fill(''));
	const email = sessionStorage.getItem('userEmail') || '';
	const [emailError, setEmailError] = useState('');
	const [showCriteria, setShowCriteria] = useState(false);
	const [passwordError, setPasswordError] = useState('');
	const [confirmPasswordError, setConfirmPasswordError] = useState('');
	const [otpError, setOtpError] = useState('');
	const [user, setUser] = useState({
		password: '',
		password_confirmation: '',
	});
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	useEffect(() => {
		passwordInputRef.current?.focus();
	}, []);

	const handleFormFieldChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	): void => {
		const { name, value } = e.target;
		setUser({
			...user,
			[name]: value,
		});

		if (name === 'password') {
			setPasswordError('');
			setShowCriteria(true);
		}
	};

	const handleFormSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const { hasError, errors } = setErrorIfInvalidResetPassword(user);
		setPasswordError(errors.password);
		setConfirmPasswordError(errors.confirmPassword);

		const fullOtp = otp.join('');
		if (fullOtp.length < 6) {
			setOtpError('Please enter a 6-digit OTP.');
			return;
		}

		if (!hasError && !otpError) {
			try {
				const resetData = { ...user, otp: fullOtp, email };
				const message = await ResetPasswordSubmit(resetData);

				dispatch(
					setToast({
						toast: { message, status: true },
					})
				);
				sessionStorage.removeItem('userEmail');
				navigate('/signin');
			} catch (error) {
				dispatch(
					setToast({
						toast: { message: (error as Error).message, status: true },
					})
				);
			}
		}
	};

	return (
		<div className="bg-[#fff] min-h-screen flex relative w-screen">
			<Advert>
				<div className="flex flex-col items-center">
					<h1 className="mb-[50px] text-[30px]">Qaptri Finace</h1>
					<p className="text-[20px]">Reset your password</p>
				</div>
			</Advert>
			<div className="lg:ml-[550px] xl:ml-[650px] h-full max-sm:w-full max-w-[400px] sm:max-w-[500px] sm:min-w-[500px] mx-auto relative sm:p-8 p-4 min-h-screen">
				<div className="w-full flex flex-col items-center mt-[20px]">
					{/* <img src={logo} alt="logo" className="w-[282px] h-[40px] mb-[113px]" /> */}
					<div className="flex flex-col w-full items-center gap-y-[27px] mb-[47px]">
						<div className="flex">
							<h1 className="text-[#4b4d54] text-[1.875rem] font-bold leading-normal">
								Reset Password
							</h1>
						</div>
						<p className="text-[#8e8e93] text-center text-[0.9rem] font-normal leading-normal">
							Enter your email address below and we will send an OTP
						</p>
					</div>
					<form
						className="w-full flex flex-col gap-[24px]"
						onSubmit={handleFormSubmit}
					>
						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								inputType="text"
								placeholder="Enter your email address"
								value={email}
								inputName="email"
								handleFocused={() => setEmailError('')}
								isReadOnly={true}
							/>
							{emailError && (
								<span className="text-[0.75rem] font-normal text-red-500">
									{emailError}
								</span>
							)}
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								labelName="Password"
								inputType="text"
								placeholder="Enter password (min. of 8 characters)"
								value={user.password}
								inputName="password"
								handleChange={handleFormFieldChange}
								handleFocused={() => setPasswordError('')}
								isPassword
								passwordFocus={passwordInputRef}
							/>
							{passwordError && (
								<div>
									<span className="text-[0.75rem] font-normal text-red-500">
										{passwordError}
									</span>
								</div>
							)}
							{showCriteria && (
								<div>
									<PasswordValidator password={user.password} />
								</div>
							)}
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								labelName="Confirm password"
								inputType="text"
								placeholder="Enter password (min. of 8 characters)"
								value={user.password_confirmation}
								inputName="password_confirmation"
								handleChange={handleFormFieldChange}
								handleFocused={() => setConfirmPasswordError('')}
								isPassword
							/>
							<span className="text-[0.75rem] font-normal text-red-500">
								{confirmPasswordError}
							</span>
						</div>
						<div className="flex flex-col gap-y-2">
							<span className="text-[#8E8E93]">Enter o.t.p.</span>
							<OTPInput
								length={6}
								otp={otp}
								setOTP={setOTP}
								setOtpError={setOtpError}
							/>
							{otpError && (
								<span className="text-[0.75rem] font-normal text-red-500">
									{otpError}
								</span>
							)}
						</div>

						<Button
							buttonType="submit"
							label="Reset Password"
							styles="w-full bg-[#420063] text-white"
						/>
					</form>
				</div>
				<ReturnToLogin />
			</div>
		</div>
	);
}

export default ResetPasswordStageTwo;
