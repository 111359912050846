import { Outlet, Link } from 'react-router-dom';

function Home() {
	return (
		<div className="dashboard">
			<aside className="sidebar">
				<h2>Support Dashboard</h2>
				<nav>
					<ul>
						<li>
							<Link to="home">Support Home</Link>
						</li>
						<li>
							<Link to="tickets">Tickets</Link>
						</li>
						<li>
							<Link to="faq">FAQ</Link>
						</li>
					</ul>
				</nav>
			</aside>
			<main className="content">
				<Outlet />
			</main>
		</div>
	);
}

export default Home;
