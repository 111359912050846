import { Routes, Route } from 'react-router-dom';
import { Home } from './';

const SupportRoutes = () => (
	<Routes>
		<Route path="/" element={<Home />}></Route>
	</Routes>
);

export default SupportRoutes;
