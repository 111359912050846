import { Link } from 'react-router-dom';
import { icons } from '../../utils/iconRegistry';

function ReturnToLogin() {
	return (
		<div className="mt-[15px] flex justify-center">
			<Link
				to={'/signin'}
				className="text-[#60CA70] flex items-center underline"
			>
				<span className="text-center text-[1.5rem] font-normal">
					{icons.arrowRound && <icons.arrowRound />}
				</span>
				<span className="text-center text-[0.9rem] font-normal">
					Return to Login
				</span>
			</Link>
		</div>
	);
}

export default ReturnToLogin;
