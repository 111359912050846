import { Link, Outlet } from 'react-router-dom';

function LegalLayout() {
	return (
		<section>
			<div className="flex w-full gap-x-9">
				<div className="max-w-[300px]">
					<p className="font-bold text-[24px]">Legal</p>
					<div>
						<div>
							<div>
								<Link to="privacy-policy">
									<p className='underline'>Privacy Policy</p>
								</Link>
							</div>
							<div>
								<Link to="terms-of-service">
									<p className='underline'>Terms of Service</p>
								</Link>
							</div>
							<div>
								<Link to="acceptable-use-policy">
									<p className='underline'>Acceptable Use Policy</p>
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="flex-1">
					<Outlet />
				</div>
			</div>
		</section>
	);
}

export default LegalLayout;
