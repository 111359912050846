import React from 'react';
import Select, { SingleValue } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { setSelectedGender } from '../redux/slices/genderSlice';

type GenderOption = {
	value: string;
	label: string;
};

interface GenderProps {
	handleFocused?: () => void;
}

const GenderSelect: React.FC<GenderProps> = ({ handleFocused }) => {
	const dispatch = useDispatch();
	const selectedGender = useSelector(
		(state: RootState) => state.gender.selectedGender
	);

	const options: GenderOption[] = [
		{ value: 'male', label: 'Male' },
		{ value: 'female', label: 'Female' },
		{ value: 'other', label: 'Other' },
	];

	const customStyles = {
		control: (provided: any) => ({
			...provided,
			fontSize: '0.9rem',
			border: '1px solid #e3d9e8',
			borderRadius: '8px',
			padding: '6px',
			boxShadow: 'none',
			height: '55px',
			'&:hover': { borderColor: '#420063' },
		}),

		input: (provided: any) => ({
			...provided,
			color: '#4B4D54',
		}),

		placeholder: (provided: any) => ({
			...provided,
			color: '#8e8e93',
		}),
		indicatorSeparator: (provided: any) => ({
			...provided,
			backgroundColor: '#4B4D54',
		}),

		indicatorsContainer: (provided: any) => ({
			...provided,
			cursor: 'pointer'
		}),

		dropdownIndicator: (provided: any, state: any) => ({
			...provided,
			color: state.isFocused ? '#420063' : '#4B4D54',
			'&:hover': {
				color: '#62008C',
			},
		}),
	};

	const handleChange = (selectedOption: SingleValue<GenderOption>) => {
		dispatch(setSelectedGender(selectedOption || null));
	};

	return (
		<div>
			<Select
				options={options}
				placeholder="Select your gender"
				styles={customStyles}
				isClearable
				aria-label="Gender select input"
				value={selectedGender}
				onChange={handleChange}
				onFocus={handleFocused}
			/>
		</div>
	);
};

export default GenderSelect;
