import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../utils/navlinks';
import logo from '../../assets/logo-light.png';
import { Navlinks } from '../../utils/dataTypes';
import { useAppDispatch } from '../../redux/store';
import { logoutUser } from '../../redux/slices/authSlice';

interface SideBarProps {
	navlinks: Navlinks[];
	canToggleDrawer?: boolean;
	setToggleDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Sidebar({
	navlinks,
	canToggleDrawer,
	setToggleDrawer,
}: SideBarProps) {
	const navigate = useNavigate();
	const [activePath, setActivePath] = useState('dashboard');
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (pathname === '/') {
			setActivePath('dashboard');
		} else {
			const formattedPathname = pathname.split('/').slice(-1).join();
			setActivePath(formattedPathname);
		}
	}, [pathname]);

	return (
		<div className="flex-1 flex flex-col justify-between items-center py-4 w-[270px] border border-black/5 bg-[#420063] h-screen">
			<div className="py-[30px] h-full w-full flex flex-col items-center gap-y-[136px]">
				<div className="pl-[20px] flex w-full items-center">
					<Link to={'/'} className="mr-[50px]">
						<img className="w-[160px] h-[35px]" src={logo} alt="logo" />
					</Link>
					{canToggleDrawer && setToggleDrawer && (
						<span
							className="text-[25px] cursor-pointer"
							onClick={() => setToggleDrawer(false)}
						>
							x
						</span>
					)}
				</div>
				<nav className="h-full">
					<ul className="flex flex-col justify-start items-center gap-1">
						{navlinks.map((nav) => {
							const iconText = nav.name.toLowerCase();
							return (
								<li key={nav.name} className="flex items-center cursor-pointer">
									<button
										className={`flex items-center gap-x-[15px] pl-[20px] w-[225px] h-[54px] outline-none border ${
											activePath === iconText
												? 'border-[#e3d9e8] bg-[#ece9ee] text-[#420063] rounded-lg'
												: 'border-transparent text-[#fff] bg-transparent'
										}`}
										onClick={() => {
											navigate(`${iconText}`);
										}}
									>
										<span className="text-[20px]">
											{nav.icon && <nav.icon />}
										</span>
										<span className="text-[13px]">{nav.name}</span>
									</button>
								</li>
							);
						})}
					</ul>
				</nav>
				<div>
					<button
						className="flex items-center gap-x-[15px] pl-[20px] w-[225px] h-[54px] outline-none border-none bg-transparent cursor-pointer"
						onClick={() => {
							dispatch(logoutUser());
							navigate('/signin');
						}}
					>
						<span className="text-[20px] text-red-700">
							{logout.icon && <logout.icon />}
						</span>
						<span className="text-[13px] text-[#fff]">{logout.name}</span>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Sidebar;
