import { CheckBoxProps } from './dataTypes';

function CheckBox({
	children,
	checked,
	onChange,
	id,
	ChangeTerms,
}: CheckBoxProps) {
	return (
		<label
			htmlFor={id}
			className="flex items-start gap-2 cursor-pointer text-justify leading-[0.9rem]"
		>
			<input
				id={id}
				type="checkbox"
				checked={checked}
				onChange={(e) => {
					onChange(e.target.checked);
					if (ChangeTerms) {
						ChangeTerms('');
					}
				}}
				className="accent-[#420063] cursor-pointer"
			/>
			{children && (
				<span className="text-[14px] text-[#4B4D54]">{children}</span>
			)}
		</label>
	);
}

export default CheckBox;
