import { forwardRef } from 'react';

interface ProgressBarProps {
	progress: number; // value from 0 to 100 to represent progress
}

const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
	({ progress }, ref) => (
		<div className="w-full h-[4px] bg-transparent rounded-bl-[4px] overflow-hidden">
			<div
				ref={ref}
				style={{
					width: `${progress}%`,
					height: '100%',
					backgroundColor: 'rgb(216 180 254)',
					transition: 'width 0.3s ease',
				}}
			></div>
		</div>
	)
);

ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
