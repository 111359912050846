import { AxiosResponse } from 'axios';
import axios from './axios';
import {
	SignupUserInterface,
	OTPInterface,
	handleSignupResponse,
	userDataResponse,
	handleUseresponse,
	SigninUserInterface,
	ResetPasswordInterface,
} from '../utils/dataTypes';
import { axiosErrorHandler } from '../utils/helpers';

const SIGNUP_URL = 'api/customer/auth/sign_up';
const SIGNIN_URL = 'api/customer/auth/sign_in';
const VERIFY_OTP_URL = 'api/customer/auth/verify_otp';
const SEND_OTP_URL = 'api/customer/auth/send_otp';
const RESET_PASSWORD_URL = 'api/customer/auth/reset_password';
const SET_SECURITY_PINS = 'api/customer/security_pins';

export const handleSignupSubmit = async (
	user: SignupUserInterface
): Promise<string> => {
	try {
		const response: AxiosResponse<handleSignupResponse> = await axios.post(
			SIGNUP_URL,
			JSON.stringify({
				customer: user,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		if (response.status === 201 && response.data.email) {
			sessionStorage.setItem('userEmail', response.data.email);
			return 'Please complete the sign up by entering the otp sent to your email.';
		}
		throw new Error('Unexpected server response. Please try again.');
	} catch (error) {
		const errorMessage = axiosErrorHandler(error);
		throw new Error(errorMessage);
	}
};

export const handleSigninSubmit = async (
	user: SigninUserInterface
): Promise<handleUseresponse | undefined> => {
	try {
		const response: AxiosResponse<userDataResponse> = await axios.post(
			SIGNIN_URL,
			JSON.stringify({
				customer: user,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		if (response.status === 201 && response.data.token) {
			sessionStorage.setItem('token', response.data.token);
			sessionStorage.setItem('role', response.data.user_role.name);

			return {
				token: response.data.token,
				full_name: response.data.full_name,
				email: response.data.email,
				role: response.data.user_role.name,
			};
		}
		throw new Error('Unexpected server response. Please try again.');
	} catch (error) {
		const errorMessage = axiosErrorHandler(error);
		throw new Error(errorMessage);
	}
};

export const handleOTPSubmit = async (
	user: OTPInterface
): Promise<handleUseresponse | undefined> => {
	try {
		const response: AxiosResponse<userDataResponse> = await axios.post(
			VERIFY_OTP_URL,
			JSON.stringify({
				customer: user,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		if (response.status === 201 && response.data.token) {
			sessionStorage.setItem('token', response.data.token);
			sessionStorage.setItem('role', response.data.user_role.name);
			return {
				token: response.data.token,
				full_name: response.data.full_name,
				email: response.data.email,
				role: response.data.user_role.name,
			};
		}
		throw new Error('Unexpected server response. Please try again.');
	} catch (error) {
		const errorMessage = axiosErrorHandler(error);
		throw new Error(errorMessage);
	}
};

export const sendOTPSubmit = async (email: string): Promise<string> => {
	try {
		const response: AxiosResponse<void> = await axios.post(
			SEND_OTP_URL,
			JSON.stringify({ email }),
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		if (response.status === 201) {
			return 'OTP has been sent. Please check your email.';
		}

		throw new Error('Unexpected server response. Please try again.');
	} catch (error) {
		const errorMessage = axiosErrorHandler(error);
		throw new Error(errorMessage);
	}
};

export const ResetPasswordSubmit = async (
	user: ResetPasswordInterface
): Promise<string> => {
	try {
		const response: AxiosResponse<userDataResponse> = await axios.put(
			RESET_PASSWORD_URL,
			JSON.stringify({
				customer: user,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);

		if (response.status === 200) {
			return 'Your password has been reset successfully. Please log in with your new password.';
		}
		throw new Error('Unexpected server response. Please try again.');
	} catch (error) {
		const errorMessage = axiosErrorHandler(error);
		throw new Error(errorMessage);
	}
};

export const SetSecurityPinSubmit = async (
	security_pin: {
		pin: string;
		pin_confirmation: string;
	},
	token: string | null
): Promise<string> => {
	try {
		const response: AxiosResponse<void> = await axios.post(
			SET_SECURITY_PINS,
			JSON.stringify({
				security_pin,
			}),
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: token,
				},
			}
		);

		if (response.status === 201) {
			return 'Your security pin has been set successfully.';
		}
		throw new Error('Unexpected server response. Please try again.');
	} catch (error) {
		const errorMessage = axiosErrorHandler(error);
		throw new Error(errorMessage);
	}
};
