import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PhoneDataType, PhoneDataState } from '../../utils/dataTypes';

const initialState: PhoneDataState = {
	phoneData: null,
};

const phoneSlice = createSlice({
	name: 'phone',
	initialState,
	reducers: {
		setPhone: (state, action: PayloadAction<PhoneDataType | null>) => {
			state.phoneData = action.payload;
		},
	},
});

export const { setPhone } = phoneSlice.actions;
export default phoneSlice.reducer;
