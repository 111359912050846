import { Routes, Route, Navigate } from 'react-router-dom';
import { Home } from '.';
import { Transaction, Dashboard, Referral } from './';
import { NotFound } from '../components/index';
import { SecurityPin } from '../../auth';

const CustomerRoutes = () => (
	<Routes>
		<Route path="/" element={<Home />}>
			<Route index element={<Dashboard />} />
			<Route path="dashboard" element={<Navigate to="/" replace />} />
			<Route path="transactions" element={<Transaction />} />
			<Route path="referrals" element={<Referral />} />
			<Route path="settings" element={<SecurityPin />} />
		</Route>
		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default CustomerRoutes;
