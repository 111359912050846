import { useEffect, useState } from 'react';
import { OTPInput, ReturnToLogin } from './components';
import { handleOTPSubmit, sendOTPSubmit } from '../services/auth';
import { useAppDispatch } from '../redux/store';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../redux/slices/authSlice';
import { setToast } from '../redux/slices/appSlice';

const OTPVerification = () => {
	const [otp, setOTP] = useState<string[]>(Array(6).fill(''));
	const [otpError, setOtpError] = useState('');
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleResendOTP = async () => {
		const email = sessionStorage.getItem('userEmail') || '';

		if (!email) {
			dispatch(
				setToast({
					toast: {
						message: 'Email is missing. Please enter a valid email.',
						status: true,
					},
				})
			);
			return;
		}

		try {
			const message = await sendOTPSubmit(email);
			dispatch(
				setToast({
					toast: { message, status: true },
				})
			);
		} catch (error) {
			dispatch(
				setToast({
					toast: { message: (error as Error).message, status: true },
				})
			);
		}
	};

	const handleSubmit = async () => {
		const fullOtp = otp.join('');
		const email: string = sessionStorage.getItem('userEmail') || '';

		if (!email) {
			dispatch(
				setToast({
					toast: {
						message: 'Email is missing. Please enter a valid email.',
						status: true,
					},
				})
			);
			return;
		}

		try {
			const response = await handleOTPSubmit({ otp: fullOtp, email });
			if (response) {
				dispatch(setUser(response));
				sessionStorage.removeItem('userEmail');
				dispatch(
					setToast({
						toast: { message: 'Signed up successfully.', status: true },
					})
				);

				navigate('/customer');
			}
		} catch (error) {
			dispatch(
				setToast({
					toast: { message: (error as Error).message, status: true },
				})
			);
		}
	};

	useEffect(() => {
		if (otp.join('').length === 6) {
			handleSubmit();
		}
	}, [otp]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="w-full min-h-screen">
			<div className="max-w-[369px] h-full m-auto flex flex-col justify-center items-center mt-[100px]">
				<h2 className="text-[#4b4d54] text-[30px] font-bold leading-normal mb-[34px]">
					Confirm O.T.P.
				</h2>
				<p className="text-[#8E8E93] text-center text-[15px] font-normal leading-normal mb-[58px]">
					A 6-digit confirmation code was sent to your email address, please
					enter it below.
				</p>

				<div className="mb-[22px]">
					<OTPInput
						length={6}
						resendOTP={handleResendOTP}
						otp={otp}
						setOTP={setOTP}
						setOtpError={setOtpError}
					/>
					{otpError && (
						<span className="text-[0.75rem] font-normal text-red-500">
							{otpError}
						</span>
					)}
				</div>
			</div>
			<ReturnToLogin />
		</div>
	);
};

export default OTPVerification;
