import { iconRegistry } from './';
import { Navlinks } from './dataTypes';

export const customerNavlinks: Navlinks[] = [
	{
		name: 'Dashboard',
		icon: iconRegistry.dashboard,
		link: '/dashboard',
	},
	{
		name: 'Transactions',
		icon: iconRegistry.transaction,
		link: '/transactions',
	},
	{
		name: 'Referrals',
		icon: iconRegistry.referral,
		link: '/referrals',
	},
	{
		name: 'Settings',
		icon: iconRegistry.settings,
		link: '/settings',
	},
];

export const adminNavlinks: Navlinks[] = [
	{
		name: 'Dashboard',
		icon: iconRegistry.dashboard,
		link: '/dashboard',
	},
	{
		name: 'Transactions',
		icon: iconRegistry.transaction,
		link: '/transactions',
	},
	{
		name: 'Customers',
		icon: iconRegistry.customer,
		link: '/referrals',
	},
	{
		name: 'Settings',
		icon: iconRegistry.settings,
		link: '/settings',
	},
];

export const logout = {
	name: 'Logout',
	icon: iconRegistry.logout,
	link: '/signin',
};
