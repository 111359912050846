interface AdvertProps {
	children: React.ReactNode;
}

function Advert({ children }: AdvertProps) {
	return (
		<div className="hidden fixed h-screen lg:flex flex-col justify-between items-center py-4 lg:w-[500px] xl:w-[600px] border border-black/5 bg-[#420063] text-[#fff]">
			<div className="py-[30px] h-full w-full flex flex-col items-center gap-y-[136px] mt-[100px]">
				<p className="text-[40px]">Post Advert</p>
				{children}
			</div>
		</div>
	);
}

export default Advert;
