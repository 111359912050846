import { Country } from '../utils/dataTypes';

export const countries: Country[] = [
	{
		countryName: 'Nigeria',
		areaCode: '+234',
		countryFlag: 'https://flagcdn.com/w320/ng.png',
		countryCode: 'NG',
	},
	{
		countryName: 'Angola',
		areaCode: '+244',
		countryFlag: 'https://flagcdn.com/w320/ao.png',
		countryCode: 'AO',
	},
	{
		countryName: 'Ghana',
		areaCode: '+233',
		countryFlag: 'https://flagcdn.com/w320/gh.png',
		countryCode: 'GH',
	},
	{
		countryName: 'Kenya',
		areaCode: '+254',
		countryFlag: 'https://flagcdn.com/w320/ke.png',
		countryCode: 'KE',
	},
	{
		countryName: 'South Africa',
		areaCode: '+27',
		countryFlag: 'https://flagcdn.com/w320/za.png',
		countryCode: 'ZA',
	},
	{
		countryName: 'United States',
		areaCode: '+1',
		countryFlag: 'https://flagcdn.com/w320/us.png',
		countryCode: 'US',
	},
	{
		countryName: 'United Kingdom',
		areaCode: '+44',
		countryFlag: 'https://flagcdn.com/w320/gb.png',
		countryCode: 'GB',
	},
	{
		countryName: 'Zambia',
		areaCode: '+260',
		countryFlag: 'https://flagcdn.com/w320/zm.png',
		countryCode: 'ZM',
	},
];
