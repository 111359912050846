import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { appState, App } from '../../utils/dataTypes';

const initialState: App = {
	app: {
		toast: {
			status: false,
			message: '',
			type: undefined
		}
	},
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setToast: (state, action: PayloadAction<appState>) => {
			state.app.toast = action.payload.toast;
		},
	},
});

export const { setToast } = appSlice.actions;

export const getToast = (state: RootState): appState => state.app.app;

export default appSlice.reducer;
