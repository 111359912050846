import { ButtonProps } from './dataTypes';

function Button({ label, styles, buttonType }: ButtonProps) {
	return (
		<button
			type={buttonType}
			className={`${styles} capitalize py-[14px] rounded-lg`}
		>
			{label}
		</button>
	);
}

export default Button;
