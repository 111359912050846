import { Outlet } from 'react-router-dom';
import { Sidebar } from '../components';
import { customerNavlinks } from '../../utils/navlinks';
import { Navbar } from '../../components';

function Home() {
	return (
		<div className="relative bg-[#fff] min-h-screen flex text-white">
			<div className="lg:flex transform -translate-x-full lg:translate-x-0 transition-transform duration-700 ease-in-out fixed left-0 top-0 h-full z-50">
				<Sidebar navlinks={customerNavlinks} canToggleDrawer={false}/>
			</div>
			<main className="flex-1 w-full mx-auto lg:ml-[270px]">
				<Navbar navlinks={customerNavlinks}/>
				<Outlet />
			</main>
		</div>
	);
}

export default Home;
