import React, { useState } from 'react';
import { logoutUser } from '../redux/slices/authSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { logout } from '../utils/navlinks';
import { useNavigate } from 'react-router-dom';
import { icons } from '../utils/iconRegistry';
import { RxHamburgerMenu } from 'react-icons/rx';
import { Sidebar } from '../pages/components';
import { Navlinks } from '../utils/dataTypes';

interface NavbarProps {
	navlinks: Navlinks[];
}

const Navbar: React.FC<NavbarProps> = ({ navlinks }) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [toggleDrawer, setToggleDrawer] = useState(false);
	const user = useAppSelector((state) => state.auth.user);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const toggleDropdown = () => {
		setIsDropdownOpen((prev) => !prev);
	};

	const closeDropdown = () => {
		setIsDropdownOpen(false);
	};

	const displayName = user?.full_name
		?.split(' ')
		.map((name) => {
			return name.slice(0, 1).toUpperCase();
		})
		.join('')
		.toUpperCase();

	return (
		<>
			<nav className="flex justify-between items-center px-6 py-4 border-b border-black/5 w-full">
				<div className="flex w-full justify-between">
					<div className="flex justify-center items-center">
						<div className="lg:hidden flex justify-between items-center relative mr-[30px]">
							<span
								className="cursor-pointer"
								onClick={() => setToggleDrawer((prev) => !prev)}
							>
								<RxHamburgerMenu className="text-white w-[34px] h-[34px] bg-[#420064] hover:bg-[#420046] p-1.5 rounded-full" />
							</span>
						</div>

						<div className="flex text-lg font-bold text-[#420064]">Home</div>
					</div>

					<div className="lg:flex relative">
						<button
							onClick={toggleDropdown}
							className="flex items-center gap-2 px-4 py-2 bg-[#420064] hover:bg-[#420046] rounded text-white"
						>
							{user.full_name ? <span>{displayName}</span> : <span>USER</span>}
							<span className="text-lg">
								{icons.arrowDown && <icons.arrowDown />}
							</span>
						</button>
						{isDropdownOpen && (
							<>
								<ul className="absolute right-0 mt-2 w-40 bg-white text-gray-800 shadow-lg rounded z-50">
									<li
										onClick={closeDropdown}
										className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-x-[15px] w-full outline-none border-none bg-transparent"
									>
										<span className="text-[20px] text-[#420064]">
											{icons.user && <icons.user />}
										</span>
										<span className="text-[13px] text-[#8e8e93]">Profile</span>
									</li>
									<li
										onClick={() => {
											closeDropdown();
											navigate('/settings');
										}}
										className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-x-[15px] w-full outline-none border-none bg-transparent"
									>
										<span className="text-[20px] text-[#420064]">
											{icons.settings && <icons.settings />}
										</span>
										<span className="text-[13px] text-[#8e8e93]">Settings</span>
									</li>
									<li
										onClick={() => {
											dispatch(logoutUser());
											navigate('/signin');
										}}
										className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-x-[15px] w-full outline-none border-none bg-transparent"
									>
										<span className="text-[20px] text-red-700">
											{logout.icon && <logout.icon />}
										</span>
										<span className="text-[13px] text-[#8e8e93]">
											{logout.name}
										</span>
									</li>
								</ul>
								<div
									className="fixed inset-0 bg-black/50 z-40"
									onClick={closeDropdown}
								/>
							</>
						)}
					</div>
				</div>
			</nav>

			{/* toggleDrawer */}
			<div
				className={`lg:hidden absolute bg-black/20 top-0 right-0 left-0 bg-tranparent z-50 shadow-secondary h-screen transition-all duration-500 ease-in-out ${
					toggleDrawer ? 'translate-x-0' : '-translate-x-full'
				}`}
			>
				{toggleDrawer && (
					<Sidebar
						navlinks={navlinks}
						canToggleDrawer={true}
						setToggleDrawer={setToggleDrawer}
					/>
				)}
			</div>
		</>
	);
};

export default Navbar;
