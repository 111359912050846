const PrivacyPolicy = () => (
	<div className="">
		<p className="font-bold text-[18px] mb-[20px]">Privacy Policy</p>
		<div>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pretium
				finibus lorem, in dictum est venenatis quis. Cras blandit condimentum
				odio eget eleifend. Proin est neque, tincidunt eget luctus non, sagittis
				eu turpis. Donec et varius diam. Vestibulum sed dui lorem. Pellentesque
				est nibh, ultricies eget consectetur ut, sagittis vulputate tortor.
				Curabitur sit amet eros sodales urna interdum faucibus. Duis at massa
				vitae ex congue commodo. Proin quis viverra neque. Maecenas mattis massa
				sit amet imperdiet commodo. Morbi leo nunc, ultrices non mauris lacinia,
				iaculis mollis mi. Phasellus diam urna, tincidunt ac elit sed, consequat
				vestibulum nunc. Integer elementum leo nunc, sit amet sodales risus
				dictum vitae. Ut at risus quis nibh interdum vulputate. Aliquam in
				ligula a est tristique finibus. Nulla lobortis mi id sollicitudin
				tincidunt. Sed interdum id felis sed vulputate. Donec tempus sodales
				libero sit amet placerat. Pellentesque in faucibus lorem, et interdum
				risus. Etiam pellentesque euismod est in semper. Donec posuere
				condimentum finibus. Pellentesque interdum blandit augue eu aliquet.
				Vivamus nec magna tempus ante dapibus aliquam a vitae lorem. Nulla in
				ligula pretium, molestie erat a, interdum arcu. In non leo lacinia,
				vulputate nibh quis, tristique tellus. Fusce at semper nisl, vel
				malesuada risus. Donec ut metus massa. Cras eu tellus tellus. Sed vitae
				nisl sagittis tellus auctor lobortis eu ac ligula. Vestibulum quis purus
				eget elit luctus ornare. Mauris risus velit, consequat at risus et,
				rutrum congue nunc. Vestibulum venenatis leo a velit lobortis ultrices.
				Mauris ultrices lorem vitae mauris vehicula, ac feugiat massa lobortis.
				Aliquam ullamcorper justo urna, nec faucibus nulla efficitur nec. Sed
				nec enim quis est fringilla interdum. Donec ligula justo, pretium in
				iaculis sed, pulvinar sed magna. Sed pretium blandit nisi, quis aliquet
				mi ullamcorper ut. Vivamus vitae libero nec justo posuere pharetra.
				Praesent vulputate suscipit finibus. Etiam fermentum mattis massa, id
				ultricies odio pellentesque quis. Phasellus vestibulum metus eu felis
				ultrices semper. Ut nec mattis eros, eget porta risus. Nullam ut
				imperdiet turpis, et malesuada felis. Etiam porta nec sapien ac aliquet.
				Mauris vel diam finibus, vehicula nunc et, efficitur est. Ut suscipit in
				sapien nec sagittis. Maecenas vitae nisl mi. Mauris mauris eros,
				accumsan in enim quis, aliquam facilisis tellus. Sed porta aliquet eros
				sit amet sodales. Sed faucibus scelerisque est a auctor. Maecenas
				sollicitudin efficitur diam, maximus hendrerit elit semper non.
			</p>
		</div>
	</div>
);

export default PrivacyPolicy;
