import { Advert, FormField } from '../components';
import { Button } from '../components';
import { useEffect, useRef, useState } from 'react';
import { validateEmail } from '../utils/validation';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../redux/store';
import { sendOTPSubmit } from '../services/auth';
import { setToast } from '../redux/slices/appSlice';
import { ReturnToLogin } from './components';

function ResetPasswordStageOne() {
	const emailInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		emailInputRef.current?.focus();
	}, []);

	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleFormSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const errors = validateEmail(email);

		if (errors.length > 0) {
			const errorString = errors.join(' ');
			setEmailError(errorString);
		} else {
			try {
				const message = await sendOTPSubmit(email);
				dispatch(
					setToast({
						toast: { message, status: true },
					})
				);

				sessionStorage.setItem('userEmail', email);
				navigate('/reset-password-2');
			} catch (error) {
				dispatch(
					setToast({
						toast: { message: (error as Error).message, status: true },
					})
				);
			}
		}
	};

	return (
		<div className="bg-[#fff] min-h-screen flex relative w-screen">
			<Advert>
				<div className="flex flex-col items-center">
					<h1 className="mb-[50px] text-[30px]">Qaptri Finace</h1>
					<p className="text-[20px]">Reset your password</p>
				</div>
			</Advert>
			<div className="lg:ml-[550px] xl:ml-[650px] h-full max-sm:w-full max-w-[400px] sm:max-w-[500px] sm:min-w-[500px] mx-auto relative sm:p-8 p-4 min-h-screen">
				<div className="w-full flex flex-col items-center mt-[20px]">
					{/* <img src={logo} alt="logo" className="w-[282px] h-[40px] mb-[113px]" /> */}
					<div className="flex flex-col w-full items-center gap-y-[27px] mb-[47px]">
						<div className="flex">
							<h1 className="text-[#4b4d54] text-[1.875rem] font-bold leading-normal">
								Reset Password
							</h1>
						</div>
						<p className="text-[#8e8e93] text-center text-[0.9rem] font-normal leading-normal">
							Enter your email address below and we will send an OTP
						</p>
					</div>
					<form
						className="w-full flex flex-col gap-[24px]"
						onSubmit={handleFormSubmit}
					>
						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								inputType="text"
								placeholder="Enter your email address"
								value={email}
								inputName="email"
								handleChange={(e) => setEmail(e.target.value)}
								emailFocus={emailInputRef}
								handleFocused={() => setEmailError('')}
							/>
							{emailError && (
								<span className="text-[0.75rem] font-normal text-red-500">
									{emailError}
								</span>
							)}
						</div>
						<Button
							buttonType="submit"
							label="Send OTP"
							styles="w-full bg-[#420063] text-white"
						/>
					</form>
					<ReturnToLogin />
				</div>
			</div>
		</div>
	);
}

export default ResetPasswordStageOne;
