import { icons } from '../utils/iconRegistry';

interface PasswordCriteria {
	label: string;
	isValid: (password: string) => boolean;
}

const passwordCriteria: PasswordCriteria[] = [
	{
		label: 'At least 8 characters',
		isValid: (password) => password.length >= 8,
	},
	{
		label: 'At least one lowercase letter',
		isValid: (password) => /[a-z]/.test(password),
	},
	{
		label: 'At least one uppercase letter',
		isValid: (password) => /[A-Z]/.test(password),
	},
	{ label: 'At least one number', isValid: (password) => /\d/.test(password) },
	{
		label: 'At least one special character',
		isValid: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
	},
	{ label: 'No whitespace', isValid: (password) => /^\S*$/.test(password) },
];

function PasswordValidator({ password }: { password: string }) {
	return (
		<div className="space-y-2">
			<p className="text-[0.75rem] font-normal mt-[2px] text-red-500">
				Must check all the criteria:
			</p>
			<div className="mt-2">
				{passwordCriteria.map((criteria, index) => {
					const isValid = criteria.isValid(password);
					return (
						<div key={index} className="flex items-center space-x-2 text-sm">
							{isValid ? (
								<span className="text-green-500">
									{icons.checkCircle && <icons.checkCircle />}
								</span>
							) : (
								<span className="text-red-500">
									{icons.timesCircle && <icons.timesCircle />}
								</span>
							)}
							<span
								className={`${
									isValid ? 'text-gray-700' : 'text-gray-500'
								} text-[0.80rem]`}
							>
								{criteria.label}
							</span>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default PasswordValidator;
