import { useState } from 'react';
import { Country } from '../utils/dataTypes';
import { countries } from '../utils/countries';
import { setPhone } from '../redux/slices/phoneSlice';
import { useAppDispatch } from '../redux/store';
import { icons } from '../utils/iconRegistry';

interface GenderProps {
	handleFocused?: () => void;
}

function PhoneNumberField({ handleFocused }: GenderProps) {
	const [selectedCountry, setSelectedCountry] = useState<Country>(countries[0]);
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const filteredCountries = countries
		.sort()
		.filter((country) =>
			country.countryName.toLowerCase().includes(searchTerm.toLowerCase())
		);

	const handleCountryChange = (country: Country) => {
		setSelectedCountry(country);
		setShowDropdown(false);
	};

	return (
		<div className="relative">
			<label htmlFor="phone_number" className="flex-1 w-full flex flex-col">
				<div className="flex w-full items-center py-[12px] sm:px-[15px] px-[12px] border-[1px] border-[#e3d9e8] rounded-[8px] sm:min-w-[200px]">
					<div
						className="flex w-[120px] items-center pr-[12px] gap-[5px] cursor-pointer text-[#8e8e93]"
						onClick={() => setShowDropdown((prev) => !prev)}
					>
						<img
							src={selectedCountry.countryFlag}
							alt={selectedCountry.countryName}
							className="w-4 h-4"
						/>
						<p className="text-sm">{selectedCountry.areaCode}</p>
						{icons.angleDown && (
							<span>
								<icons.angleDown />
							</span>
						)}
					</div>

					<input
						id="phone_number"
						type="tel"
						value={phoneNumber}
						onChange={(e) => {
							const newPhoneNumber = e.target.value;
							setPhoneNumber(newPhoneNumber);
							const phoneData = {
								...selectedCountry,
								phoneNumber: newPhoneNumber,
							};
							dispatch(setPhone(phoneData));
						}}
						onFocus={handleFocused}
						className="h-[30px] w-full px-4 text-sm outline-none border-l placeholder:text-[#8e8e93] text-[0.9rem] caret-[#8e8e93]"
						placeholder="Enter phone number"
					/>
				</div>
				{showDropdown && (
					<div className="absolute left-0 w-full mt-[4rem] bg-white border border-[#e3d9e8] rounded-lg z-10">
						<div>
							<input
								type="text"
								placeholder="Search Countries"
								className="h-[50px] w-full px-3 py-2 rounded-t-lg text-[0.9rem] bg-[#f8f8f8] focus:outline-none"
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value.trim())}
							/>
						</div>
						<ul className="max-h-60 overflow-y-auto">
							{filteredCountries.map((country) => (
								<li
									key={country.areaCode}
									onClick={() => handleCountryChange(country)}
									className="flex items-center px-4 py-3 cursor-pointer hover:bg-gray-100 border-t"
								>
									<img
										src={country.countryFlag}
										alt={country.countryName}
										className="w-4 h-4 mr-2"
									/>
									<p className="text-sm">
										{country.countryName} ({country.areaCode})
									</p>
								</li>
							))}
						</ul>
					</div>
				)}
			</label>
		</div>
	);
}

export default PhoneNumberField;
