import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from '.';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { IoIosClose } from 'react-icons/io';

interface ToastProps {
	message?: string;
	duration?: number; // duration in ms for the toast display
	onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({
	message,
	duration = 10000,
	onClose,
}) => {
	const [progress, setProgress] = useState(100);
	const progressRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const interval = setInterval(() => {
			setProgress((prev) => Math.max(0, prev - 100 / (duration / 100)));
		}, 100);

		const timer = setTimeout(() => {
			onClose();
		}, duration);

		return () => {
			clearInterval(interval);
			clearTimeout(timer);
		};
	}, [duration, onClose]);

	return (
		<div className="flex flex-col gap-y-[10px] justify-between fixed top-[20px] bottom-0 right-0 translate-x-[-50%] translate-y-[-50%] transition-transform duration-300 ease-in-out bg-[#000] text-[#fff] rounded-[4px] min-w-[300px] max-h-[85px] shadow-toastShadow mt-[40px] z-50">
			<div className="pt-[4px] flex flex-col">
				<div
					onClick={onClose}
					className="pr-[5px] flex w-full justify-end items-end"
				>
					<IoIosClose className="text-[25px] cursor-pointer text-gray-300 font-bold" />
				</div>
				<div className="pl-[10px] flex flex-row items-center">
					<IoIosCheckmarkCircle className="text-[30px] text-purple-300" />
					<span className="px-[16px] text-[13px]">{message}</span>
				</div>
			</div>
			<ProgressBar ref={progressRef} progress={progress} />
		</div>
	);
};

export default Toast;
