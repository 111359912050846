import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserDetails, User } from '../../utils/dataTypes';

const initialState: User = {
	user: {
		token: sessionStorage.getItem('token') || '',
		full_name: null,
		email: null,
		role: sessionStorage.getItem('role') || '',
	},
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<UserDetails>) => {
			state.user = action.payload;
		},

		logoutUser: (state) => {
			state.user.token = '';
			state.user.role = '';
			state.user.full_name = null;
			state.user.email = null;

			sessionStorage.removeItem('token');
			sessionStorage.removeItem('role');
		},
	},
});

export const { setUser, logoutUser } = authSlice.actions;

export const getUser = (state: RootState): UserDetails | null =>
	state.auth.user;

export default authSlice.reducer;
