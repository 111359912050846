import { FormField } from '../components';
import { Button } from '../components';
import { useEffect, useRef, useState } from 'react';
import { setErrorIfInvalidPin } from '../utils/validation';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { SetSecurityPinSubmit } from '../services/auth';
import { setToast } from '../redux/slices/appSlice';

function SecurityPin() {
	const pinInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		pinInputRef.current?.focus();
	}, []);

	const [securityPin, setSecurityPin] = useState({
		pin: '',
		pin_confirmation: '',
	});

	const [securityPinError, setSecurityPinError] = useState('');
	const [confirmSecurityPinError, setConfirmSecurityPinError] = useState('');

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const token = useAppSelector((state) => state.auth.user.token);

	const handleFormFieldChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	): void => {
		const target = e.target;
		setSecurityPin({
			...securityPin,
			[target.name]: target.value,
		});
	};

	const handleFormSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		const { hasError, errors } = setErrorIfInvalidPin(securityPin);

		setSecurityPinError(errors.pin);
		setConfirmSecurityPinError(errors.confirmPin);

		if (!hasError) {
			try {
				const response = await SetSecurityPinSubmit(securityPin, token);

				if (response) {
					dispatch(
						setToast({
							toast: { message: response, status: true },
						})
					);
					navigate('/');
				}
			} catch (error) {
				dispatch(
					setToast({
						toast: { message: (error as Error).message, status: true },
					})
				);
			}
		}
	};

	return (
		<div className="h-full max-sm:w-full max-w-[400px] sm:max-w-[500px] sm:min-w-[500px] mx-auto relative sm:p-8 p-4 min-h-screen">
			<div className="w-full flex flex-col items-center mt-[20px]">
				<div className="flex flex-col w-full items-center gap-y-[10px] mb-[99px]">
					<div className="flex">
						<h1 className="text-[#4b4d54] text-[1.875rem] font-bold leading-normal">
							Secret Pin Setup
						</h1>
					</div>
					<p className="text-[#8e8e93] text-center text-[0.9rem] font-normal leading-normal">
						Let's set your transaction security pin.
					</p>
				</div>
				<form
					className="w-full flex flex-col gap-[24px]"
					onSubmit={handleFormSubmit}
				>
					<div className="flex-1 w-full flex flex-col">
						<FormField
							isInputField={true}
							labelName="Secret pin"
							inputType="text"
							placeholder="Enter 4 digit secret"
							value={securityPin.pin}
							inputName="pin"
							handleChange={handleFormFieldChange}
							handleFocused={() => setSecurityPinError('')}
							pinFocus={pinInputRef}
							isPassword={true}
						/>
						{securityPinError && (
							<span className="text-[0.75rem] font-normal text-red-500">
								{securityPinError}
							</span>
						)}
					</div>

					<div className="flex-1 w-full flex flex-col">
						<FormField
							isInputField={true}
							labelName="Confirm pin"
							inputType="text"
							placeholder="Re-enter the 4 digit secre"
							value={securityPin.pin_confirmation}
							inputName="pin_confirmation"
							handleChange={handleFormFieldChange}
							handleFocused={() => setConfirmSecurityPinError('')}
							isPassword={true}
						/>
						{confirmSecurityPinError && (
							<span className="text-[0.75rem] font-normal text-red-500">
								{confirmSecurityPinError}
							</span>
						)}
					</div>

					<Button
						buttonType="submit"
						label="Submit"
						styles="w-full bg-[#420063] text-white"
					/>
				</form>
			</div>
		</div>
	);
}

export default SecurityPin;
