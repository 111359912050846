import { FaTachometerAlt } from 'react-icons/fa';
import { FaUserGroup } from 'react-icons/fa6';
import { HiMiniSquaresPlus } from 'react-icons/hi2';
import { IoSettingsSharp } from 'react-icons/io5';
import { ImSwitch } from 'react-icons/im';
import { PiUsersThreeFill } from 'react-icons/pi';
import { FaUserAlt } from 'react-icons/fa';
import { TiArrowSortedDown } from 'react-icons/ti';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { TfiAngleDown } from 'react-icons/tfi';
import { IoMdEye } from 'react-icons/io';
import { IoMdEyeOff } from 'react-icons/io';
import { BiSolidError } from 'react-icons/bi';
import { RxHamburgerMenu } from 'react-icons/rx';

const iconRegistry: Record<string, React.ComponentType> = {
	dashboard: FaTachometerAlt,
	transaction: HiMiniSquaresPlus,
	referral: PiUsersThreeFill,
	customer: FaUserGroup,
	settings: IoSettingsSharp,
	logout: ImSwitch,
	user: FaUserAlt,
	arrowDown: TiArrowSortedDown,
	arrowRound: IoIosArrowRoundBack,
	checkCircle: FaCheckCircle,
	timesCircle: FaTimesCircle,
	angleDown: TfiAngleDown,
	eyeOn: IoMdEye,
	eyeOff: IoMdEyeOff,
	errorIcon: BiSolidError,
	hamburger: RxHamburgerMenu,
};

export const icons = {
	settings: iconRegistry.settings,
	user: iconRegistry.user,
	arrowDown: iconRegistry.arrowDown,
	arrowRound: iconRegistry.arrowRound,
	checkCircle: iconRegistry.checkCircle,
	timesCircle: iconRegistry.timesCircle,
	angleDown: iconRegistry.angleDown,
	eyeOn: iconRegistry.eyeOn,
	eyeOff: iconRegistry.eyeOff,
	errorIcon: iconRegistry.errorIcon,
	hamburger: iconRegistry.hamburger,
};

export default iconRegistry;
