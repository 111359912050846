import { useState } from 'react';
import { FormProps } from './dataTypes';
import { GenderSelect, PhoneNumberField } from '../auth';
import { icons } from '../utils/iconRegistry';

function FormField({
	isReadOnly,
	labelName,
	placeholder,
	inputType,
	value,
	isPhoneField,
	isSelectField,
	isInputField,
	inputName,
	isPassword,
	emailFocus,
	firstNameFocus,
	passwordFocus,
	pinFocus,
	handleFocused,
	handleChange,
}: FormProps) {
	const [togglePassword, setTogglePassword] = useState(false);

	const togglePasswordVisibility = () => {
		setTogglePassword((prevType) => !prevType);
	};

	return (
		<>
			<label className="flex-1 w-full flex flex-col">
				{labelName && (
					<span className="font-medium text-[0.875rem] leading-[22px] text-[#4B4D54] mb-[5px]">
						{labelName}
					</span>
				)}
				{isInputField && (
					<div
						className={`flex-1 w-full flex flex-col ${
							isPassword ? 'relative' : ''
						}`}
					>
						{isPassword && (
							<span
								className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-[#8e8e93]"
								onClick={togglePasswordVisibility}
							>
								{togglePassword ? (
									<span className="text-[#420063] text-[1.3rem]">
										<icons.eyeOff />
									</span>
								) : (
									<span className="text-[#420063] text-[1.3rem]">
										<icons.eyeOn />
									</span>
								)}
							</span>
						)}
						<input
							value={value}
							name={inputName}
							onFocus={handleFocused}
							onChange={handleChange}
							readOnly={isReadOnly}
							ref={
								emailFocus
									? emailFocus
									: firstNameFocus
									? firstNameFocus
									: passwordFocus
									? passwordFocus
									: pinFocus
							}
							type={
								isPassword ? (togglePassword ? 'text' : 'password') : inputType
							}
							placeholder={placeholder}
							className={`h-[55px] py-[12px] sm:px-[15px] px-[12px] outline-none border-[1px] border-[#e3d9e8] bg-transparent text-[0.9rem] placeholder:text-[#8e8e93] rounded-[8px] sm:min-w-[200px] caret-[#8e8e93] focus:outline-none focus:border-[#420063] text-[#4B4D54]`}
						/>
					</div>
				)}
			</label>
			{isPhoneField && <PhoneNumberField handleFocused={handleFocused} />}

			{isSelectField && <GenderSelect handleFocused={handleFocused} />}
		</>
	);
}

export default FormField;
