import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenderState, GenderOption} from '../../utils/dataTypes'

const initialState: GenderState = {
	selectedGender: null,
};

const genderSlice = createSlice({
	name: 'gender',
	initialState,
	reducers: {
		setSelectedGender: (state, action: PayloadAction<GenderOption | null>) => {
			state.selectedGender = action.payload;
		},
	},
});

export const { setSelectedGender } = genderSlice.actions;
export default genderSlice.reducer;
