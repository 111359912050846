import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CustomerRoutes } from './pages/customer';
import { AdminRoutes } from './pages/admin';
import { SupportRoutes } from './pages/support/';
import {
  Signup,
  OTPVerification,
  ResetPasswordStageOne,
  ResetPasswordStageTwo,
} from './auth';
import { Signin } from './auth';
import {
  LegalLayout,
  TermsOfService,
  AcceptableTerms,
  PrivacyPolicy,
} from './legal/pages';
import { useAppDispatch, useAppSelector } from './redux/store';
import { Toast } from './Toast';
import { setToast } from './redux/slices/appSlice';
import { ProtectedRoute } from './auth/components';
import { NotFound } from './components';

function App() {
  const user = useAppSelector((state) => state.auth.user);
  const toast = useAppSelector((state) => state.app.app.toast);
  const dispatch = useAppDispatch();

  const getRoleRoutes = () => {
    switch (user.role) {
      case 'admin':
        return <AdminRoutes />;
      case 'customer':
        return <CustomerRoutes />;
      case 'support':
        return <SupportRoutes />;
      default:
        return <Navigate to="/404" replace />;
    }
  };

  return (
    <main className="flex flex-col bg-[#fff] w-screen">
      {toast.status && (
        <Toast
          message={toast.message}
          duration={10000}
          onClose={() => dispatch(setToast({ toast: { status: false } }))}
        />
      )}
      <Routes>
        {/* Public Routes */}
        <Route path="/legal" element={<LegalLayout />}>
          <Route index element={<PrivacyPolicy />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-service" element={<TermsOfService />} />
          <Route path="acceptable-use-policy" element={<AcceptableTerms />} />
        </Route>

        {!user.token ? (
          <>
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset-password-1" element={<ResetPasswordStageOne />} />
            <Route path="/reset-password-2" element={<ResetPasswordStageTwo />} />
            <Route path="/verify-otp" element={<OTPVerification />} />
            <Route path="*" element={<Navigate to="/signin" replace />} />
          </>
        ) : (
          <>
            {/* Role-based Home Page */}
            <Route
              path="*"
              element={
                <ProtectedRoute requiredRole={user.role} userRole={user.role}>
                  {getRoleRoutes()}
                </ProtectedRoute>
              }
            />

            {/* Redirect Auth Pages */}
            <Route path="/signin" element={<Navigate to="/" replace />} />
            <Route path="/signup" element={<Navigate to="/" replace />} />
            <Route path="/reset-password-1" element={<Navigate to="/" replace />} />
            <Route path="/reset-password-2" element={<Navigate to="/" replace />} />
            <Route path="/verify-otp" element={<Navigate to="/" replace />} />
          </>
        )}

        <Route path="/404" element={<NotFound />} />
        {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
      </Routes>
    </main>
  );
}

export default App;
