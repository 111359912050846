import { useAppSelector } from '../../redux/store';

function Dashboard() {
	const user = useAppSelector((state) => state.auth.user);

	return (
		<div className="text-black max-w-[1280px] mx-auto px-[30px] mt-[30px]">
			Customer Dashboard
			<p className="">
				Hi <span className="">{user?.full_name}</span>
			</p>
		</div>
	);
}

export default Dashboard;
