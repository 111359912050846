import React, { useEffect, useRef, useState } from 'react';
import { Button, CheckBox, FormField } from '../components';
import { wave } from '../assets';
import { useAppSelector } from '../redux/store';
import { PasswordValidator } from './';
import { initialUserState } from './utils';
import { setErrorIfInvalid } from '../utils/validation';
import { useNavigate } from 'react-router-dom';
import { handleSignupSubmit } from '../services/auth';
import { useAppDispatch } from '../redux/store';
import { setToast } from '../redux/slices/appSlice';
import { Advert } from '../components';
import { ReturnToLogin } from './components';
import { icons } from '../utils/iconRegistry';

function Signup() {
	const [user, setUser] = useState(initialUserState);
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [middleNameError, setMiddleNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [genderError, setGenderError] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [confirmPasswordError, setConfirmPasswordError] = useState('');
	const [termsError, setTermsError] = useState('');
	const [showCriteria, setShowCriteria] = useState(false);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [receiveUpdates, setReceiveUpdates] = useState(false);
	const [referralCodeError, setReferralCodeError] = useState('');
	const [errorResponse, setErrorResponse] = useState('');
	const dispatch = useAppDispatch();

	const firstNameInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		firstNameInputRef.current?.focus();
	}, []);

	const selectedGender = useAppSelector((state) => state.gender.selectedGender);
	const phoneData = useAppSelector((state) => state.phone.phoneData);

	const navigate = useNavigate();

	const handleFormFieldChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	): void => {
		const target = e.target;
		setUser({
			...user,
			[target.name]: target.value,
		});

		if (target.name === 'password') {
			setPasswordError('');
			setShowCriteria(true);
		}
	};

	const handleFormSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setErrorResponse('');

		const { hasError, errors } = setErrorIfInvalid(
			user,
			selectedGender,
			phoneData,
			acceptTerms
		);

		setFirstNameError(errors.firstName);
		setLastNameError(errors.lastName);
		setEmailError(errors.email);
		setPhoneError(errors.phoneNumber);
		setMiddleNameError(errors.middleName);
		setReferralCodeError(errors.referralCode);
		setGenderError(errors.gender);
		setPasswordError(errors.password);
		setConfirmPasswordError(errors.confirmPassword);
		setTermsError(errors.termsOfUse);

		if (!hasError) {
			const newUser = {
				...user,
				terms_of_use: acceptTerms,
				news_and_promotionals: receiveUpdates,
				phone_number: `${phoneData?.areaCode}${phoneData?.phoneNumber}`,
				gender: selectedGender?.value,
				country: phoneData?.countryName,
			};

			try {
				const message = await handleSignupSubmit(newUser);
				const email = sessionStorage.getItem('userEmail') || '';

				if (!email) {
					dispatch(
						setToast({
							toast: {
								message: 'Return to sign up',
								status: true,
							},
						})
					);
					return;
				}

				dispatch(
					setToast({
						toast: { message, status: true },
					})
				);
				navigate('/verify-otp');
			} catch (error) {
				setErrorResponse((error as Error).message);
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
		}
	};

	return (
		<div className="bg-[#fff] min-h-screen flex relative w-screen">
			<Advert>
				<div className="flex flex-col items-center">
					<h1 className="mb-[50px] text-[30px]">Qaptri Finace</h1>
					<p className="text-[20px]">Create a USD Account in Seconds</p>
				</div>
			</Advert>

			<div className="lg:ml-[550px] xl:ml-[650px] h-full max-sm:w-full max-w-[450px] sm:max-w-[550px] mx-auto relative sm:p-8 p-4 min-h-screen">
				<div className="flex flex-col items-center mt-[20px]">
					<div className="flex flex-col w-full items-center gap-y-[10px] mb-[67px]">
						<div className="flex items-center">
							<h1 className="text-[#4b4d54] text-[1.875rem] font-bold leading-normal">
								Get Started
							</h1>
							<img src={wave} alt="wave" className="w-[42px] h-[42px]" />
						</div>
						<p className="text-[#8e8e93] text-center text-[0.9rem] font-normal leading-normal">
							Create an account to start receiving USD now.
						</p>
					</div>

					{errorResponse && (
						<div className="w-full flex mb-[28px] bg-red-200 py-3.5 justify-center items-center rounded-lg">
							<span className="text-red-600 mr-[10px]">
								{icons.errorIcon && <icons.errorIcon />}
							</span>
							<span className="text-[13px]">{errorResponse}</span>
						</div>
					)}

					<form
						className="w-full flex flex-col gap-[24px]"
						onSubmit={handleFormSubmit}
					>
						<div className="flex flex-wrap gap-y-[24px] gap-x-[9px]">
							<div className="flex-1 w-full flex flex-col">
								<FormField
									isInputField={true}
									labelName="First name"
									inputType="text"
									placeholder="Enter your legal first name"
									value={user.first_name}
									inputName="first_name"
									handleChange={handleFormFieldChange}
									handleFocused={() => setFirstNameError('')}
									firstNameFocus={firstNameInputRef}
								/>
								{firstNameError && (
									<span className="text-[0.75rem] font-normal text-red-500">
										{firstNameError}
									</span>
								)}
							</div>
							<div className="flex-1 w-full flex flex-col">
								<FormField
									isInputField={true}
									labelName="Middle name (optional)"
									inputType="text"
									placeholder="Enter your legal middle name"
									value={user.middle_name}
									inputName="middle_name"
									handleChange={handleFormFieldChange}
									handleFocused={() => setMiddleNameError('')}
								/>
								{middleNameError && (
									<span className="text-[0.75rem] font-normal text-red-500">
										{middleNameError}
									</span>
								)}
							</div>
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								labelName="Last name"
								inputType="text"
								placeholder="Enter your legal last name"
								value={user.last_name}
								inputName="last_name"
								handleChange={handleFormFieldChange}
								handleFocused={() => setLastNameError('')}
							/>
							{lastNameError && (
								<span className="text-[0.75rem] font-normal  text-red-500">
									{lastNameError}
								</span>
							)}
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								labelName="Email address"
								inputType="text"
								placeholder="Enter your email address"
								value={user.email}
								inputName="email"
								handleChange={handleFormFieldChange}
								handleFocused={() => setEmailError('')}
							/>
							{emailError && (
								<span className="text-[0.75rem] font-normal text-red-500">
									{emailError}
								</span>
							)}
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isSelectField={true}
								handleChange={handleFormFieldChange}
								handleFocused={() => setGenderError('')}
								labelName="Gender"
							/>
							<span className="text-[0.75rem] font-normal text-red-500">
								{genderError}
							</span>
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isPhoneField={true}
								handleChange={handleFormFieldChange}
								handleFocused={() => setPhoneError('')}
								labelName="Phone number"
							/>
							{phoneError && (
								<span className="text-[0.75rem] font-normal text-red-500">
									{phoneError}
								</span>
							)}
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								labelName="Password"
								inputType="text"
								placeholder="Enter password (min. of 8 characters)"
								value={user.password}
								inputName="password"
								handleChange={handleFormFieldChange}
								handleFocused={() => setPasswordError('')}
								isPassword={true}
							/>
							{passwordError && (
								<div>
									<span className="text-[0.75rem] font-normal text-red-500">
										{passwordError}
									</span>
								</div>
							)}
							{showCriteria && (
								<div>
									<PasswordValidator password={user.password} />
								</div>
							)}
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								labelName="Confirm password"
								inputType="text"
								placeholder="Enter password (min. of 8 characters)"
								value={user.password_confirmation}
								inputName="password_confirmation"
								handleChange={handleFormFieldChange}
								handleFocused={() => setConfirmPasswordError('')}
								isPassword={true}
							/>
							<span className="text-[0.75rem] font-normal text-red-500">
								{confirmPasswordError}
							</span>
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								labelName="Referral code (optional)"
								inputType="text"
								placeholder="Referral code of user that referred you"
								value={user.referral_code}
								inputName="referral_code"
								handleChange={handleFormFieldChange}
							/>
							{referralCodeError && (
								<span className="text-[0.75rem] font-normal text-red-500">
									{referralCodeError}
								</span>
							)}
						</div>
						<div>
							<CheckBox
								id="receive_updates"
								checked={receiveUpdates}
								onChange={setReceiveUpdates}
							>
								I agree to receive product updates, announcements, and exclusive
								offers via email.
							</CheckBox>
						</div>

						<div>
							<CheckBox
								id="accept_terms"
								checked={acceptTerms}
								onChange={setAcceptTerms}
								error={termsError}
								ChangeTerms={setTermsError}
							>
								I accept the{' '}
								<a href="/legal/terms-of-service" className="text-[#420063] underline" target='_blank'>
									Terms of Use
								</a>{' '}
								and{' '}
								<a href="/legal/privacy-policy" className="text-[#420063] underline" target='_blank'>
									Privacy Policy.
								</a>
							</CheckBox>
							{termsError && (
								<span className="text-[0.75rem] font-normal text-red-500">
									{termsError}
								</span>
							)}
						</div>
						<Button
							buttonType="submit"
							label="Sign up"
							styles="w-full bg-[#420063] text-white"
						/>
					</form>
				</div>
				<ReturnToLogin />
			</div>
		</div>
	);
}

export default Signup;
