export const initialUserState = {
	first_name: '',
	middle_name: '',
	last_name: '',
	email: '',
	password: '',
	password_confirmation: '',
	referral_code: '',
};

