import axios from 'axios';

export const capitalize = (value: string) => {
	const firstElement = value.slice(0, 1).toUpperCase();
	const lastElements = value.slice(1);

	return `${firstElement}${lastElements}`;
};

export const axiosErrorHandler = (error: unknown): string => {
	if (axios.isAxiosError(error)) {
		// Server responded with an error
		if (error.response) {
			const responseData = error.response.data;

			// Handle structured errors (e.g., { errors: { field: ['issue'] } })
			if (responseData?.errors) {
				const fieldErrors = Object.entries(responseData.errors)
					.map(([field, messages]) => {
						if (field === 'password' || field === 'email') {
							if ((messages as string[]).includes('invalid')) {
								return 'Invalid email or password';
							} else {
								return `${capitalize(field)} ${(messages as string[]).join(
									', '
								)}`;
							}
						} else {
							return `${(messages as string[]).join(', ')}`;
						}
					})
					.join('; ');
				return `${fieldErrors}`;
			}

			// Generic server error
			return `${responseData.message || 'Unknown server error'}`;
		}

		// Network or configuration error
		return `Network error: ${error.message || 'Please check your connection.'}`;
	}

	// Non-Axios errors
	return `Unexpected error: ${
		error instanceof Error ? error.message : String(error)
	}`;
};
