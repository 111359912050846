import { Advert, FormField } from '../components';
import wave from '../assets/wave.svg';
import { Button } from '../components';
import { useEffect, useRef, useState } from 'react';
import { setErrorIfInvalidLogin } from '../utils/validation';
import { Link, useNavigate } from 'react-router-dom';
import { handleSigninSubmit } from '../services/auth';
import { useAppDispatch } from '../redux/store';
import { setUser } from '../redux/slices/authSlice';
import { setToast } from '../redux/slices/appSlice';
import { icons } from '../utils/iconRegistry';

function Signin() {
	const emailInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		emailInputRef.current?.focus();
	}, []);

	const [userObj, setUserObj] = useState({
		email: '',
		password: '',
	});
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [errorResponse, setErrorResponse] = useState('');
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleFormFieldChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>
	): void => {
		const target = e.target;
		setUserObj({
			...userObj,
			[target.name]: target.value,
		});
	};

	const handleFormSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setErrorResponse('');

		const { hasError, errors } = setErrorIfInvalidLogin(userObj);

		setEmailError(errors.email);
		setPasswordError(errors.password);

		if (!hasError) {
			try {
				const userData = await handleSigninSubmit(userObj);
				if (userData) {
					dispatch(setUser(userData));

					if (sessionStorage.getItem('token')) {
						const role = sessionStorage.getItem('role');
						navigate(`/${role}`);

						dispatch(
							setToast({
								toast: { status: true, message: 'Logged in successfully' },
							})
						);
					}
				}
			} catch (error) {
				setErrorResponse((error as Error).message);
			}
		}
	};

	return (
		<div className="bg-[#fff] min-h-screen flex relative w-screen">
			<Advert>
				<div className="flex flex-col items-center">
					<h1 className="mb-[50px] text-[30px]">Qaptri Finace</h1>
					<p className="text-[20px]">Login in to access your account</p>
				</div>
			</Advert>
			<div className="lg:ml-[550px] xl:ml-[650px] h-full max-sm:w-full max-w-[400px] sm:max-w-[500px] sm:min-w-[500px] mx-auto relative sm:p-8 p-4 min-h-screen">
				<div className="w-full flex flex-col items-center mt-[20px]">
					{/* <img src={logo} alt="logo" className="w-[282px] h-[40px] mb-[113px]" /> */}
					<div className="flex flex-col w-full items-center gap-y-[10px] mb-[99px]">
						<div className="flex">
							<h1 className="text-[#4b4d54] text-[1.875rem] font-bold leading-normal">
								Welcome back
							</h1>
							<img src={wave} alt="wave" className="w-[42px] h-[42px]" />
						</div>
						<p className="text-[#8e8e93] text-center text-[0.9rem] font-normal leading-normal">
							Please enter your acount details
						</p>
					</div>

					{errorResponse && (
						<div className="w-full flex mb-[28px] bg-red-200 py-3.5 justify-center items-center rounded-lg">
							<span className="text-red-600 mr-[10px]">
								{icons.errorIcon && <icons.errorIcon />}
							</span>
							<span className="text-[13px]">{errorResponse}</span>
						</div>
					)}

					<form
						className="w-full flex flex-col gap-[24px]"
						onSubmit={handleFormSubmit}
					>
						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								inputType="text"
								placeholder="Enter your email address"
								value={userObj.email}
								inputName="email"
								handleChange={handleFormFieldChange}
								emailFocus={emailInputRef}
								handleFocused={() => setEmailError('')}
							/>
							{emailError && (
								<span className="text-[0.75rem] font-normal text-red-500">
									{emailError}
								</span>
							)}
						</div>

						<div className="flex-1 w-full flex flex-col">
							<FormField
								isInputField={true}
								inputType="text"
								placeholder="Enter password"
								value={userObj.password}
								inputName="password"
								handleChange={handleFormFieldChange}
								handleFocused={() => setPasswordError('')}
								isPassword={true}
							/>
							{passwordError && (
								<div>
									<span className="text-[0.75rem] font-normal text-red-500">
										{passwordError}
									</span>
								</div>
							)}
						</div>

						<Button
							buttonType="submit"
							label="Login"
							styles="w-full bg-[#420063] text-white"
						/>
					</form>
				</div>
				<div className="w-full mt-[28px] flex items-center justify-center">
					<p className="text-[#B0B0B0] text-[0.81rem]  font-normal">
						Not registered yet?
					</p>
					<Link
						to={'/signup'}
						className="text-[#60CA70] flex items-center underline"
					>
						<span className="text-center text-[0.81rem] font-normal ml-1">
							Register here
						</span>
					</Link>
				</div>
				<div className="w-full mt-[8px] flex items-center justify-center">
					<p className="text-[#B0B0B0] text-[0.81rem]  font-normal">
						Forgot password?
					</p>
					<Link
						to={'/reset-password-1'}
						className="text-[#60CA70] flex items-center underline"
					>
						<span className="text-center text-[0.81rem] font-normal ml-1">
							Reset password here
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Signin;
