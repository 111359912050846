import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  requiredRole: string | null;
  userRole: string | null;
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  requiredRole,
  userRole,
  children,
}) => {
  if (!userRole || userRole !== requiredRole) {
    return <Navigate to="/404" replace />;
  }
  return <>{children}</>;
};

export default ProtectedRoute;
