import { SignupUserObj, SigninUserInterface } from '../utils/dataTypes';
import { GenderOption, PhoneDataType } from '../utils/dataTypes';

export function validateName(name: string) {
	const errors: string[] = [];

	if (name.length > 0 && name[0] === ' ') {
		errors.push('First character cannot have space');
	}

	if (!/^[A-Za-z]/.test(name)) {
		errors.push('First character must be a letter');
	}

	if (/[0-9]/.test(name)) {
		errors.push('Numbers are not allowed');
	}

	if (name.length < 2) {
		errors.push('Cannot be less than 2 characters');
	}

	if (name.length > 24) {
		errors.push('Cannot be more than 24 characters');
	}

	return errors;
}

export function validateEmail(email: string) {
	const errors: string[] = [];

	if (/\s/.test(email)) {
		errors.push('Email cannot have space');
	}

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (!emailRegex.test(email)) {
		errors.push('Email must be valid e.g., (example@gmail.com)');
	}

	return errors;
}

export function validatePhoneNumber(phoneNumber: string, code: string) {
	const errors: string[] = [];

	if (!/^\d+$/.test(phoneNumber)) {
		errors.push('Phone number must be numeric');
	}

	if (/\s/.test(phoneNumber)) {
		errors.push('Phone number cannot have space');
	}

	const phonePatterns: { [key: string]: RegExp } = {
		AO: /^[0-9]{9}$/,
		GH: /^[0-9]{9}$/,
		KE: /^[0-9]{9}$/,
		NG: /^[0-9]{11}$/,
		ZA: /^[0-9]{10}$/,
		US: /^[0-9]{10}$/,
		GB: /^[0-9]{10}$/,
		ZM: /^[0-9]{9}$/,
	};

	const pattern = phonePatterns[code];
	if (!pattern) errors.push('Invalid country code');
	if (!pattern.test(phoneNumber)) errors.push(`Phone number must be ${pattern.toString().match(/\{(\d+)\}/)?.[1]} digits`);

	return errors;
}

export const validateReferralCode = (value: string) => {
	const errors: string[] = [];
	const alphanumericRegex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]+$/;

	if (value.trim().length < 6) {
		errors.push('At least 6 characters');
	}

	if (value.length > 6) {
		if (value[0] !== ' ') {
			if (!alphanumericRegex.test(value)) {
				errors.push('letters and numbers only');
			}
		}
	}
	return errors;
};

export const validateSecretPin = (value: string) => {
	const errors: string[] = [];

	if (!/^\d+$/.test(value)) {
		errors.push('Secret pin must be numeric');
	}

	if (value.trim().length !== 4) {
		errors.push('Must be 4 characters');
	}

	return errors;
};

export const setErrorIfInvalid = (
	user: SignupUserObj,
	selectedGender: GenderOption | null,
	phoneData: PhoneDataType | null,
	acceptTerms: boolean
) => {
	let hasError = false;

	const errors = {
		firstName: '',
		lastName: '',
		middleName: '',
		email: '',
		phoneNumber: '',
		referralCode: '',
		gender: '',
		password: '',
		confirmPassword: '',
		termsOfUse: '',
	};

	errors.firstName = !user.first_name.trim()
		? 'First name cannot be blank'
		: validateName(user.first_name).join(', ');
	if (errors.firstName) hasError = true;

	errors.lastName = !user.last_name.trim()
		? 'Last name cannot be blank'
		: validateName(user.last_name).join(', ');
	if (errors.lastName) hasError = true;

	errors.email = !user.email.trim()
		? 'Email cannot be blank'
		: validateEmail(user.email).join(', ');
	if (errors.email) hasError = true;

	errors.phoneNumber = !phoneData?.phoneNumber.trim()
		? 'Phone number cannot be blank'
		: validatePhoneNumber(phoneData.phoneNumber, phoneData.countryCode).join(
				', '
		  );
	if (errors.phoneNumber) hasError = true;

	// Validate optional fields
	errors.middleName = user.middle_name
		? validateName(user.middle_name).join(', ')
		: '';
	if (errors.middleName) hasError = true;

	errors.referralCode = user.referral_code
		? validateReferralCode(user.referral_code).join(', ')
		: '';
	if (errors.referralCode) hasError = true;

	// Validate gender
	errors.gender = !selectedGender?.label.trim() ? 'Gender is required' : '';
	if (errors.gender) hasError = true;

	// Validate password and confirm password
	errors.password = !user.password.trim() ? 'Password cannot be blank' : '';
	if (!user.password.trim()) hasError = true;

	errors.confirmPassword = !user.password_confirmation.trim()
		? 'Confirm password cannot be blank'
		: user.password !== user.password_confirmation
		? 'Passwords must match'
		: '';
	if (
		!user.password_confirmation.trim() ||
		user.password !== user.password_confirmation
	)
		hasError = true;

	errors.termsOfUse = !acceptTerms
		? 'Must accept privacy policy and terms of use'
		: '';
	if (errors.termsOfUse) hasError = true;

	return { hasError, errors };
};

export const setErrorIfInvalidPin = (securityPin: {
	pin: string;
	pin_confirmation: string;
}) => {
	let hasError = false;

	const errors = {
		pin: '',
		confirmPin: '',
	};

	errors.pin = !securityPin.pin.trim()
		? 'Secret pin cannot be blank'
		: validateSecretPin(securityPin.pin).join(', ');
	if (errors.pin) hasError = true;

	errors.confirmPin = !securityPin.pin_confirmation.trim()
		? 'confirm secret pin cannot be blank'
		: securityPin.pin_confirmation !== securityPin.pin
		? 'Security pins must match'
		: '';
	if (
		!securityPin.pin_confirmation.trim() ||
		securityPin.pin_confirmation !== securityPin.pin
	)
		hasError = true;

	return { hasError, errors };
};

export const setErrorIfInvalidLogin = (user: SigninUserInterface) => {
	let hasError = false;

	const errors = {
		email: '',
		password: '',
	};

	errors.email = !user.email.trim()
		? 'Email cannot be blank'
		: validateEmail(user.email).join(', ');
	if (errors.email) hasError = true;

	errors.password = !user.password.trim() ? 'Password cannot be blank' : '';
	if (!user.password.trim()) hasError = true;

	return { hasError, errors };
};

export const setErrorIfInvalidResetPassword = (user: {
	password: string;
	password_confirmation: string;
}) => {
	let hasError = false;

	const errors = {
		password: '',
		confirmPassword: '',
	};

	errors.password = !user.password.trim() ? 'Password cannot be blank' : '';
	if (!user.password.trim()) hasError = true;

	errors.confirmPassword = !user.password_confirmation.trim()
		? 'Confirm password cannot be blank'
		: user.password !== user.password_confirmation
		? 'Passwords must match'
		: '';
	if (
		!user.password_confirmation.trim() ||
		user.password !== user.password_confirmation
	)
		hasError = true;

	return { hasError, errors };
};
