import { configureStore } from '@reduxjs/toolkit';
import genderReducer from './slices/genderSlice';
import phoneReducer from './slices/phoneSlice';
import authSlice from './slices/authSlice';
import appSlice from './slices/appSlice'
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

const store = configureStore({
	reducer: {
		gender: genderReducer,
		phone: phoneReducer,
		auth: authSlice,
		app: appSlice
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
