interface OTPInputProps {
	length: number;
	resendOTP?: () => void;
	otp: string[];
	setOTP: React.Dispatch<React.SetStateAction<string[]>>;
	setOtpError: React.Dispatch<React.SetStateAction<string>>;
}

const OTPInput: React.FC<OTPInputProps> = ({
	length,
	resendOTP,
	otp,
	setOTP,
	setOtpError,
}) => {
	const handleChange = (value: string, index: number) => {
		if (!/^\d$/.test(value) && value !== '') return;

		const newOtp = [...otp];
		newOtp[index] = value;
		setOTP(newOtp);

		if (value && index < length - 1) {
			const nextInput = document.getElementById(`otp-input-${index + 1}`);
			nextInput?.focus();
		}
	};

	const handleKeyDown = (
		e: React.KeyboardEvent<HTMLInputElement>,
		index: number
	) => {
		if (e.key === 'Backspace' && !otp[index] && index > 0) {
			const prevInput = document.getElementById(`otp-input-${index - 1}`);
			prevInput?.focus();
		}
	};

	return (
		<div className="w-full flex flex-col justify-center items-center">
			<div className="flex w-full gap-x-3">
				{otp.map((digit, index) => (
					<input
						key={index}
						id={`otp-input-${index}`}
						type="text"
						value={digit}
						onChange={(e) => handleChange(e.target.value, index)}
						onKeyDown={(e) => handleKeyDown(e, index)}
						maxLength={1}
						onFocus={() => setOtpError('')}
						className="border border-[#e3d9e8] bg-[#fff] w-[50px] h-[50px] text-center rounded-[3px] px-4 py-2 focus:border focus:border-[#420063] focus:outline-none focus:bg-[#e3d9e8]"
					/>
				))}
			</div>
			<div className="w-full flex items-center justify-end">
				{resendOTP && (
					<button onClick={resendOTP} className="cursor-pointer">
						<span className="text-[#cfcfd8] text-[15px] font-normal leading-normal">
							Resend O.T.P.
						</span>
					</button>
				)}
			</div>
		</div>
	);
};

export default OTPInput;
