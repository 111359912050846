import { Routes, Route, Navigate } from 'react-router-dom';
import { Home, Dashboard, Transaction, Customer, Setting } from './';
import { NotFound } from '../components';


const AdminRoutes = () => (
	<Routes>
		<Route path="/" element={<Home />}>
			<Route index element={<Dashboard />} />
			<Route path='dashboard'element={<Navigate to="/" replace />} />
			<Route path="transactions" element={<Transaction />} />
			<Route path="customers" element={<Customer />} />
			<Route path="settings" element={<Setting />} />
		</Route>
		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default AdminRoutes;
