const NotFound = () => {
	return (
		<div className="h-screen flex flex-col items-center justify-center bg-gray-100">
			<h1 className="text-4xl font-bold text-gray-800">404 - Page Not Found</h1>
			<p className="mt-4 text-gray-600">You do not have access to this page.</p>
		</div>
	);
};

export default NotFound;
